$primary-color: #eee;
$secondary-color: #000;
$link-color: #24b7ee;

$base-background-color: #282c34;
$container-background-color: #f5f6fa;
$sidebar-background-color: white;
$black-color: black;

$pragma-color: #4ebed7;

$link-disabled: #95969b;
$link-enabled: #587dd6;

$checkboxLabelColor: #696a72;

$card-text-color: #686868;
$card-text-bottom-color: #a4a5a6;

$icon-color: #d0d4d5;
$form-item-background-color: rgba(255, 255, 255, 0.64);

$top-bar-border: #ebeff0;

$white: #fff;
$cornflower-blue: #587dd6;

$textfield-error-state-background-color: #fce8e8;
$textfield-error-state-color: #e86558;
$textfield-font-color: #696a72;
$textfield-shadow-color: rgba(214, 217, 231, 0.29);

$row-box-shadow-color: #dbe4f9;
$row-box-shadow-left-border: $icon-color;

$row-box-shadow-left-border-folder-hover: #d7994e;
$row-box-shadow-left-border-folder-hover-border: rgba(215, 153, 78, 0.3);

$row-box-shadow-left-border-form-hover: $pragma-color;
$row-box-shadow-left-border-form-hover-border: rgba(78, 190, 215, 0.3);

$modal-box-shadow-color: #d0d5e3;
$modal-box-shadow-blur-color: rgba(0, 0, 0, 0.2);
$modal-paper-border: $top-bar-border;
$modal-paper-background-color: #ffffff;

$select-border-color: #cfd6e9;
$select-background-color: $form-item-background-color;
$select-icon-color: $link-enabled;
$select-text-color: $link-disabled;

$drop-zone-border-color: $icon-color;
$drop-zone-background-color: $form-item-background-color;
$drop-zone-text-color: $link-disabled;

$date-picker-text: #838383;

$folder-bread-crumb-separator: #aeaeb3;

$row-disabled: #f5f5f5;

$border-color: #d0d4d5;

$dark-sky-blue: #4ebed7;
$light-blue: #7de3f4;
