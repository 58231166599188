.generic-option-section {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;	

	.option-section {
		width: 100%;
		margin-right: 25px;
		min-width: 50%;
	}

	.go-to-section {
		width: 100%;
		margin-left: 25px;
		min-width: 20%;

		.MuiOutlinedInput-root {
			height: 54px;
		}
	}
}