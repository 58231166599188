.title--required {
	color: red;
}

.input-number {
	max-width: 150px;
}

.input-select {
	max-width: 250px;
}

.input-date {
	max-width: 150px;
	margin-bottom: 10px !important;
}