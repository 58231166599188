.variable-option {
	display: flex;
	flex-direction: row;
	justify-content: space-between;

	.variable-label {
		width: 100%;
	}

	.option-variable {
		width: 100%;
		margin-left: 10px;

		.MuiFormControl-root {
			width: 100%;
		}
	}
}