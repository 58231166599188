@import '../../themes/colors.scss';

.drop-zone {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	border: solid 1px $drop-zone-border-color !important;
	background-color: $drop-zone-background-color !important;
}

.drop-zone-paragraph {
	font-size: 14px !important;
	font-weight: normal !important;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43 !important;
	letter-spacing: normal;
	text-align: center;
	color: $drop-zone-text-color;
}

.text-link {
	color: $cornflower-blue;
}

.drop-container{
	display: flex;
	align-items: baseline;
	flex-direction: row;
	justify-content: center;
	margin: 0px !important;
	width: 100% !important;
}

.drop-item {
	width: 100%;
	height: 100%;
}