.mathematical-operator {
	.title {
		font-size: 20px;
		margin-bottom: 10px;
		color: gray;
	}

	.operator {
		
	}

	.options {
		.option {
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.fields {
				width: 100%;
				margin-bottom: 10px;
			}

			.close-button {
				margin-bottom: 10px;
			}
		}
	}
}