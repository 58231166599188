@import '../../themes/colors.scss';
@import '../../themes/mixins.scss';
$textfield-font-size: 14px;

.textfield-container {
	text-align: left;

	.textfield-helper-text.MuiFormHelperText-root.Mui-error {
		color: $textfield-error-state-color;
		font-size: $textfield-font-size - 2;
	}
}

.input-container {
	background-color: $white;
	border-radius: 2px;
	min-width: 150px;
	padding: 2% 5%;
	@include box-shadow(-2px 5px 6px 0 $textfield-shadow-color);

	.textfield-input {
		margin-left: 0.2em;
		font-size: $textfield-font-size;
		color: $textfield-font-color;
	}

	.textfield-label.Mui-error {
		color: $textfield-error-state-color;
	}

	.MuiInputLabel-root {
		font-size: $textfield-font-size;
	}

	.MuiInputBase-root {
		width: 100%;
		align-items: baseline;
	}

	.adornment.MuiIconButton-root {
		color: $textfield-error-state-color;
	}
}

.error-state {
	background-color: $textfield-error-state-background-color;
}
