@import "../../themes/colors.scss";

.topBar {
	box-shadow: none !important;

	.toolBar {
		background-color: $white;
		display: flex;
		justify-content: space-between;
		border-bottom: 1px solid $top-bar-border;

		.leftSection {
			&:hover {
				cursor: pointer;
			}
		}

		.rightSection {
			display: flex;
			align-items: center;

			.toolBarSearch {
				width: 300px;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.6;
				letter-spacing: normal;
			}

			.toolBarSearchUnderline {
				&:before {
					border-bottom: none;
				}
				&:after {
					border-bottom: none;
				}
			}

			.searchIcon {
				color: $link-disabled;
				font-size: 24px;
			}

			.toolBarReportButton {
				width: 145px;
				font-size: 15px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.6;
				letter-spacing: normal;
				color: $link-enabled;
			}

			.notificationIcon {
				color: $icon-color;
				font-size: 28px;
			}

			.divider {
				margin: 8px 24px 8px 24px;
			}

			.topbar-avatar {
				cursor: pointer;
			}
		}
	}
}
