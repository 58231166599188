.checkbox-question {
	display: flex;
		padding-top: 24px;
		padding-left: 24px;
		padding-right: 22px;
		flex-direction: column;
		justify-content: flex-start;
		text-align: left;

		.title {
			width: 100%;
		}

		.option-type {
			width: 300px;
			.MuiOutlinedInput-root {
				height: 54px;
			}
		}

		.validation {
			width: 300px;		
		}

		.option {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.title-option {
				width: 100%;
			}
		}

		.add-option {
			margin-bottom: 10px;
		}
}