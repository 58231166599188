@import '../../themes/colors.scss';

%text-style {
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
	color: $card-text-color;
}

.content {
	.form {
		&__title {
			font-size: 42px;
			font-weight: bold;
			line-height: 1.11;
			@extend %text-style;
		}

		&__description {
			font-size: 24px;
			font-weight: normal;
			line-height: 1.11;
			@extend %text-style;
		}
	}

	.section {
		&__header {
			margin-bottom: 40px;
			margin-top: 40px;
			@extend %text-style;
		}

		&__title {
			font-size: 32px;
			font-weight: bold;
			line-height: 1.11;
		}

		&__description {
			margin-top: 10px;
			font-size: 18px;
			font-weight: normal;
			line-height: 1.11;
		}

		.question {
			margin-bottom: 15px;

			&__title {
				margin-bottom: 5px;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.11;
				@extend %text-style;
			}

			&__description {
				margin-bottom: 5px;
				font-size: 14px;
				font-weight: normal;
				line-height: 1.11;
				@extend %text-style;
			}
		}
	}
}
