@import '../../../themes/colors.scss';

.MuiButton-root.action-button {
	height: 42px;
  border-radius: 21px;
  box-shadow: 0 4px 10px 0 rgba(88, 125, 214, 0.6);
	background-color: $cornflower-blue;
	text-transform: none;
	font-size: calc(10px + 0.7vmin);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
	color: $white;
	min-width: 150px;
	
	&:hover {
		background-color: lighten($cornflower-blue, 10%);
	}
}

.MuiButton-root.action-button-secondary {
	height: 42px;
  border-radius: 21px;
  box-shadow: 0 4px 10px 0 rgba(88, 125, 214, 0.6);
	background-color: $textfield-font-color;
	text-transform: none;
	font-size: calc(10px + 0.7vmin);
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
	color: $white;
	min-width: 150px;
	
	&:hover {
		background-color: lighten($textfield-font-color, 10%);
	}
}

.Mui-disabled.action-button {
	background-color: lighten($cornflower-blue, 20%);
	color: $white !important;
}

.Mui-disabled.action-button-secondary {
	background-color: lighten($textfield-font-color, 20%);
	color: $white !important;
}
