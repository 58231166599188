@import '../../../themes/colors.scss';

.leftTopCornerSection {
	display: flex;
	flex-direction: row;
	align-items: center;

	.title {
		height: 24px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.09;
		letter-spacing: normal;
		font-size: 22px;
		color: $pragma-color;
		margin-right: 34px;
		margin-left: 8px;
		text-align: left;
	}
}

