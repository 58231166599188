@import '../../themes/colors.scss';


.subheader-divider {
	margin-bottom: 32px !important;
}

.subheader {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;

	.folder-bread-crumbs {
		display: flex;
		flex: 7;
		max-width: 80%;

		ol, li {
			max-width: 100%;
			text-align: left;
		}

		.link-router {
			font-size: 22px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.09;
			letter-spacing: normal;
			color: $card-text-color;
			cursor: pointer;

			a {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				word-wrap: break-word;
			}
		}

		.bread-crumbs-separator {
			color: $folder-bread-crumb-separator;
		}

		.current-page {
			font-size: 22px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.09;
			letter-spacing: normal;
			color: $card-text-color;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			word-wrap: break-word;
		}
	}

	.action-button {
		display: flex;
		flex: 1;
	}
}
