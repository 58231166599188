@import '../../themes/colors.scss';

.home {
	text-align: center;
	background-color: $container-background-color;

	.appBar {
		position: relative;
		z-index: 100;
	}

	.breadCrumbs {
		height: 24px;
		padding-left: 128px;
		padding-top: 18px;
		display: flex;
		align-items: center;
	}

	.sideBar {
		position: fixed;
		height: 100%;
		width: 80px;
		top: 0;
		left: 0;
		padding-top: 64px;
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $sidebar-background-color;
		z-index: 99;
	}

	.container {
		margin-left: 120px;
		margin-right: 29px;
	}
}
