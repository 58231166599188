@import '../../themes/colors.scss';

%table-row {
	height: 96px;
	border-radius: 6px;
	background-color: $white;
	box-shadow: 0 2px 4px 0 $row-box-shadow-color, -4px 0px 0 0 $row-box-shadow-left-border;
}

.disabled-row {
	background-color: $row-disabled !important;
}

.folder-table-row {
	@extend %table-row;	
	&:hover {
		cursor: pointer;
		box-shadow: 0 13px 23px -5px $row-box-shadow-color , -4px 0px 0px 0 $row-box-shadow-left-border-folder-hover;
		td {border: solid 1px $row-box-shadow-left-border-folder-hover-border; border-style: solid none solid none;};
		td:first-child {border-left-style: solid};
		td:last-child {border-right-style: solid};
	}
}

.form-table-row {
	@extend %table-row;
	&:hover {
		cursor: pointer;
		box-shadow: 0 13px 23px -5px $row-box-shadow-color, -4px 0px 0px 0 $row-box-shadow-left-border-form-hover;
		td {border: solid 1px $row-box-shadow-left-border-form-hover-border; border-style: solid none solid none;};
		td:first-child {border-left-style: solid};
		td:last-child {border-right-style: solid};

	}
}

tr td:first-child { border-top-left-radius: 6px; }
tr td:last-child { border-top-right-radius: 6px; }
tr td:first-child { border-bottom-left-radius: 6px; }
tr td:last-child { border-bottom-right-radius: 6px; }
