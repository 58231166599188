.crop-container {
	position: relative;
	width: 100%;
	height: 400px;
	background: #333;
}

.crop-button {
	flex-shrink: 0;
	margin-left: 16px;
}

.crop-button-text {
	color: white;
}

.controls {
	padding: 16px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	justify-content: center;
}

.slider-container {
	display: flex;
	flex: 1;
	align-items: center;
	min-width: 320px;
}

.slider-label {
	min-width: 65px;
}

.slider {
	padding: 22px 0;
	flex-direction: row;
	align-items: center;
	margin: 0 16px;
}

.upload-image-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;

	.upload-image-button {
		display: none;
	}
}
