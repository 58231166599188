@import '../../../index.scss';
@import '../../../themes/colors.scss';

.plan-container {
	@extend %page-container;

	.table-body-plan {	
		.icon {
			color: $icon-color;
		}
		
		.icon-darker {
			filter: brightness(30%);
		}
		
		.size-cell-medium {
			width: 20%;
		}
		
		.size-cell-large {
			width: 30%;
		}
		
		.MuiOutlinedInput-input {
			text-align: right;
		}
	}
}