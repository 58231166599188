.move-folder-file-content {
	.title {
		padding:10px
	}

	.folder-file-list {
		min-height: 200px;

		.list-item-text {
			overflow: hidden;
			white-space: nowrap;
		}
	}
}
