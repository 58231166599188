@import "../../../../themes/colors.scss";

.edit-blog {
	.paper {
		border-radius: 6px;
		box-shadow: -2px 5px 6px 0 $textfield-shadow-color;
		background-color: $modal-paper-background-color;
		padding: 73px 88px 32px 88px;

		.form {
			.title-container {
				margin-bottom: 16px;

				.title-input {
					height: 128px;
					border-radius: 6px;
					border: solid 1px $icon-color;
					background-color: $form-item-background-color;
					font-size: 36px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 0.67;
					letter-spacing: normal;
					color: $card-text-color;
				}
			}

			.upload-image-section {
				margin-bottom: 17px;
				width: 100%;

				.drop-container {
					display: inline-flex;
					position: relative;

					.float-button-drop {
						position: absolute;
						right: 2px;
						top: 2px;
					}
				}

				.image-container {
					display: flex;
					position: relative;
					background-color: $black-color;

					.float-button-image {
						position: absolute;
						right: 0;

						&:hover {
							display: inline-flex;
						}
					}

					.hide {
						display: none;
					}

					.image {
						width: 100%;

						&:hover {
							cursor: pointer;
							opacity: 0.6;
						}

						&:hover + .hide {
							display: inline-flex;
						}
					}
				}

				.upload-container {
					width: 100%;
				}
			}

			.autocomplete {
				margin-bottom: 32px;
			}
		}

		.actions {
			margin-top: 32px;
			text-align: right;
		}

		.actions button:not(:first-child) {
			margin-left: 8px;
		}
	}
}
