@import "../../index.scss";
@import "../../themes/colors.scss";

.user-container {
	@extend %page-container;

	.icon {
		color: $icon-color;
	}

	.icon-darker {
		filter: brightness(30%);
	}

	.size-cell-medium {
		width: 20%;
	}

	.size-cell-large {
		width: 30%;
	}

	.add-user-modal {
		.modal-field {
			margin-bottom: 16px;
		}

		.MuiFormControl-marginNormal {
			margin-top: 0 !important;
			margin-bottom: 0 !important;
		}
	}
}
