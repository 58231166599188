@import '../../themes/colors.scss';

.paperCard {
  height: 96px;

  &:hover {
    cursor: pointer;
  }

  .gridContainer {
    height:100%;
    align-items: center;

    .icon {
      color: $link-enabled;
      font-size: 32px;
    }

    .title {
      font-size: 18px;
      color: $card-text-color;
    }
  }
}
