.logic-operator {
	.title {
		font-size: 20px;
		margin-bottom: 10px;
		color: gray;
	}

	.operator {
		display: flex;
		flex-direction: row;
		align-items: center;	
		margin-bottom: 22px;

		.operator-title {
			margin-right: 10px;
			width: 100px;
		}		
	}

	.options {
		.option {
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.fields {
				width: 100%;
				margin-bottom: 10px;
			}

			.close-button {
				margin-bottom: 10px;
			}
		}

		.opertation-symbol {
			display: flex;
			justify-content: center;
			margin-bottom: 10px;
			font-size: 30px;
		}
	}
}