@import '../../themes/colors.scss';

.cell-description {
	display: flex;
	align-items: center;

	.icon-cell-description {
		margin-right: 28px;
	}

	.icon-folder {
		color: $row-box-shadow-left-border-folder-hover;
	}

	.icon-file {
		color: $icon-color;
	}

	.text {
		display: flex;
		flex-direction: column;

		.title {
			font-size: 18px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: normal;
			color: $card-text-color;
			margin-bottom: 1px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			max-width: 430px;
		}

		.description {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: $link-disabled;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			max-width: 430px;
		}
	}
}
