@import '../../../themes/colors.scss';

$textfield-font-size: 14px;

.default-input-container {
	.MuiInputLabel-formControl {
		font-size: $textfield-font-size;
	}

	.text-field {
		width: 100%;
		background-color: $white;
		min-width: 150px;
	}

	.text-input {
		font-size: $textfield-font-size;
		color: $textfield-font-color;
		margin-left: 0.2em;
	}

	.text-input-error {
		background-color: $textfield-error-state-background-color;
	}

	.text-input-helper {
		text-align: right;
	}

	.adornment.MuiIconButton-root {
		color: $textfield-error-state-color;
	}
}