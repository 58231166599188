@import '../../../index.scss';
@import '../../../themes/colors.scss';

.document-container {
	@extend %page-container;

	.paper {
		border-radius: 6px;
  	box-shadow: -2px 5px 6px 0 $textfield-shadow-color;
		background-color: $modal-paper-background-color;

		.form-stepper {
			width: 200px;
		}
	}
}