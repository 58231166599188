@import '../../themes/colors.scss';

.error-screen-container {
	text-align: center;
	min-height: 90vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: $white;

	.logo {
		display: block;
		margin: 10% auto 5%;
	}

	.error-text {
		font-size: calc(8px + 2vmin);
		font-weight: lighter;
		margin-top: 1em;

		.error-text-emphasis {
			font-weight: bold;
		}
	}
}
