@import '../../themes/colors.scss';

.question-section {
	border-radius: 6px;
	border: solid 1px $border-color;
	margin-top: 40px;
	margin-left: 45px;
	margin-right: 131px;

	.top-section {
		display: flex;
		padding-left: 24px;
		padding-right: 22px;
		justify-content: space-between;
		align-items: center;
		height: 50px;
		background-color: $card-text-color;
		text-align: left;
		font-size: 18px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.11;
		letter-spacing: normal;
		color: $white;

		.title {
			width: 100%;

			.MuiOutlinedInput-root {
				height: 48px;
			}
		}

		.question-header {
			width: 100%;
		}

		.options {
			width: 100%;
			margin-left: 50px;

			.MuiOutlinedInput-root {
				height: 35px;
			}

			.MuiFormHelperText-root {
				margin-top: 0px;
			}
		}
	}

	.bottom-section {
		border-radius: 6px;
		height: 53px;
		background-color: $container-background-color;
		display: flex;
		justify-content: flex-end;

		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.43;
		letter-spacing: normal;
		color: $link-disabled;

		.switchBase {
			color: #587dd6;
		}

		.track {
			background-color: rgba(88, 125, 214, 0.38) !important;
		}

		.more-vert-icon {
			margin-left: 30px;
		}
	}
}