@import '../../themes/colors.scss';

$breadCrumbSize: 14px;


.breadCrumbs {
	.link-router {
		color: $link-enabled;
		font-size: $breadCrumbSize;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}

	.bread-crumbs-separator {
		color: $link-disabled;
	}

	.current-page {
		color: $link-disabled;
		font-size: $breadCrumbSize;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
	}
}
