@import '../../index.scss';

.client-container {
	@extend %page-container;

	.icon {
		color: $icon-color;
	}
	
	.icon-darker {
		filter: brightness(30%);
	}
	
	.size-cell-medium {
		width: 20%;
	}
	
	.size-cell-large {
		width: 30%;
	}
}