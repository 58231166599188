.switch-operator {
	.title {
		font-size: 20px;
		margin-bottom: 10px;
		color: gray;
	}

	.operator {
		display: flex;
		flex-direction: row;
		align-items: center;	
		margin-bottom: 22px;

		.operator-title {
			margin-right: 10px;
			width: 70px;
		}
	}

	.options {

		.option {
			display: flex;
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;

			.label-when {
				margin-bottom: 10px;
				margin-right: 10px;
			}

			.label-then {
				margin-bottom: 10px;
				margin-right: 10px;
				margin-left: 5px;
			}

			.fields {
				width: 100%;
				margin-bottom: 10px;
			}

			.close-button {
				margin-bottom: 10px;
			}
		}

		.default-switch-option {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;


			.default-option {
				width: 100%;
				margin-left: 10px;
			}
		}
	}
}