@import '../../themes/colors.scss';

.common-stepper {
	%icon-text-default {
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.11;
		letter-spacing: normal;
	}

	.icon-root {
		width: 32px;
		height: 32px;
		color: $white;
		border: $pragma-color solid 1px;
		border-radius: 18px;
	}

	.icon-text {
		@extend %icon-text-default;
		fill: $pragma-color;
	}

	.icon-text-active {
		@extend %icon-text-default;
		fill: $white;
	}

	.alternative-label {
		top: 15px;
		left: calc(-50% + 17px);
    right: calc(50% + 17px);
	}

	.connector-line {
		border-color: $pragma-color;
	}

}