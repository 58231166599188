@import '../../themes/colors.scss';

.subheader-form {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 0px 47px;

	.title {
		font-size: 18px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.11;
		letter-spacing: normal;
		color: $card-text-color;
	}

	.actions {
		.action-button:nth-child(2) {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
}
