@import "../../themes/colors.scss";

.profile-modal {
	.upload-image-section {
		display: flex;
		align-items: center;
		justify-content: center;

		.drop-container {
			display: inline-flex;
			position: relative;

			.upload-container {
				display: flex;
				flex-direction: column;
				justify-content: center;
			}

			.float-button-drop {
				position: absolute;
				right: 2px;
				top: 2px;
			}
		}

		.image-container {
			display: flex;
			position: relative;
			height: 320px;
			width: 320px;

			&:hover {
				cursor: pointer;
				opacity: 0.6;

				.hide {
					display: inline-flex;
				}
			}

			.float-button-image {
				position: absolute;
				right: 0;

				&:hover {
					display: inline-flex;
				}
			}

			.hide {
				display: none;
			}

			.image {
				width: 100%;
				height: 100%;
			}
		}

		.upload-container {
			height: 320px;
		}
	}

	.divider {
		margin: 3vmin 0;
		display: flex;
		align-items: center;
	}
}
