@import '../../index.scss';
@import '../../themes/colors.scss';

.dashboard-container {
  @extend %page-container;
  padding: 24px;

  .divider--margin {
    margin: 20px 0;
  }
}
