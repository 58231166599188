@import '../../index.scss';
@import '../../themes/colors.scss';

%flex-column {
	display: flex;
	flex-direction: column;
}

.MuiButton-root.invitation-button {
	background-color: $white;
	border-radius: 2px;
	box-shadow: -2px 5px 6px 0 rgba(214, 217, 231, 0.29);
	color: $textfield-font-color;
	display: flex;
	font-size: calc(10px + 0.4vmin);
	font-stretch: normal;
	font-style: normal;
	font-weight: normal;
	height: 60px;
	justify-content: flex-start;
	letter-spacing: 0.4px;
	line-height: normal;
	min-width: 150px;
	text-transform: none;
}

.invitation {
	@extend %page-container;
	width: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);

	.invitation-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 41vmin;
		margin: 5vmin 0;

		.invitation-logo-container {
			width: 16vmin;
			margin-bottom: 5vmin;

			.invitation-logo {
				width: 100%;
			}
		}

		.invitation-title {
			font-size: calc(10px + 1.5vmin);
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: center;
			color: $textfield-font-color;
			margin-bottom: 3vmin;
		}

		.invitation-buttons-container {
			@extend %flex-column;
			width: 100%;

			button:not(:first-child) {
				margin: 2vmin 0 0 0;
			}
		}
	}
}
