.reorder-list {
	
	.reorder-list-item {

		.reorder-list-item-text {

			.MuiTypography-body2 {
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
				max-width: 200px;
			}
		}
	}
}