@import '../../../../themes/colors.scss';

.form-report {
	.report-actions {
		display: flex;
		justify-content: space-between;
		padding-left: 47px;
		padding-right: 47px;
		padding-top: 40px;

		.variables {
			display: flex;
			flex-direction: row;
			align-items: center;

			.filter-variable-control {
				width: 240px;
			}

			.variable-icon {
				color: $cornflower-blue;
				border: solid 1px $border-color;
				border-radius: 6px;
				margin-left: 15px;
			}
		}
	}

	.content {
		.editor {
			padding: 40px 47px;
		}
	}
}
