@import '../../index.scss';
@import '../../themes/colors.scss';

.forms-container {
	@extend %page-container;
}
	
.table-body-forms {
	.size-cell-medium {
		width: 20%;
	}
	
	.size-cell-large {
		width: 30%;
	}

	.icon {
		color: $icon-color;
	}
}

.popover-menu {
	.menu-item {
		min-width: 100px;
	}
}
