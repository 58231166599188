.date-question {
	display: flex;
		padding-top: 24px;
		padding-left: 24px;
		padding-right: 22px;
		flex-direction: column;
		justify-content: flex-start;

		.title {
			width: 100%;
		}

		.validation {
			width: 300px;
			text-align: left;
		}

		.validation > * {
			margin-bottom: 12px;
		}
}