@import '../../index.scss';
@import '../../themes/colors.scss';

.resource-container {
	@extend %page-container;
}
	
.table-body-resources {
	.size-cell-medium {
		width: 20%;
	}
	
	.size-cell-large {
		width: 30%;
	}

	.icon {
		color: $icon-color;
	}
}

.add-resource-modal {

	.upload-pdf-section {
		height: 320px !important;
		margin-bottom: 19px;
		display: flex;
		justify-content: center;

		.drop-container {
			display: inline-flex;
			position: relative;

			.float-button-drop {
				position: absolute;
				right: 2px;
				bottom: 2px;
			}
		}

		.pdf-container {
			display: flex;
			position: relative;
			height: 320px;
			background-color: $black-color;
			overflow: auto;

			.float-button-image {
				position: absolute;
				right: 0;

				&:hover {
					display: inline-flex;
				}
			}

			.hide {
				display: none;
			}

			.pdf {							
				width: 100%;
				
				&:hover{
					cursor: pointer;
					opacity: 0.6;
				}

				&:hover + .hide {
					display: inline-flex;
				}
			}
		}
	}	

	.upload-container {
		height: 320px;
	}
}

.popover-menu {
	.menu-item {
		min-width: 100px;
	}
}
