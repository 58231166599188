.list-operator {
	.title {
		font-size: 20px;
		margin-bottom: 10px;
		color: gray;
	}

	.line {
		display: flex;
		flex-direction: row;
		align-items: center;	
		margin-bottom: 22px;

		.line-label {
			margin-right: 10px;
			width: 70px;
		}

		.row-line {
			width: 100%;
		}
	}
}