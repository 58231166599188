@import "../../index.scss";

%flex-column {
	display: flex;
	flex-direction: column;
}

.forgot-password {
	@extend %page-container;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.forgot-password-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 60vmin;

		.subtitle {
			text-align: start;
			margin: 3rem 2rem;
		}

		.forgot-password-form {
			@extend %flex-column;
			width: 100%;

			.recover-error {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100px;
				border-radius: 2px;
				box-shadow: -2px 5px 6px 0 rgba(214, 217, 231, 0.29);
				background-color: $textfield-error-state-background-color;
				font-size: calc(10px + 0.4vmin);
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				padding: 10px;
				color: #474747;
				margin-bottom: 10px;
			}

			&__button {
				align-self: flex-end;
			}
		}
	}
}
