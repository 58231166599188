@import '../../index.scss';

%flex-column {
	display: flex;
	flex-direction: column;
}

.change-password {
	@extend %page-container;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 10rem;

	.change-password-container {
		display: flex;
		flex-direction: column;
		width: 60vmin;
		
		.subtitle {
			text-align: start;
			margin: 3rem 2rem;
		}
	
		.change-password-form {
			@extend %flex-column;
			width: 100%;

			&__button {
				align-self: flex-end;
			}
		}
	}
}