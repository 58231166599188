@import '../../themes/colors.scss';

.calendar {
	display: flex;
	flex-direction: column;
	align-items: center;

	.form-select {
		margin-bottom: 47px;
		max-width: 350px;
	}

	
	.calendar-title {
		margin-bottom: 42px;
		padding-left: 20px;
		align-self: flex-start;

		width: 300px;
		height: 24px;
		font-size: 22px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.09;
		letter-spacing: normal;
		color: $card-text-color;
	}

	.date-range {
		margin-bottom: 32px;

		.rdrCalendarWrapper {
			border: solid 1px $select-border-color;

			.rdrDateDisplayWrapper {

				.rdrDateDisplay {
					margin:0;

					.rdrDateInput  {
						height: 53px;
						display: flex;
						align-items: center;
						border-radius: 0;
						margin: 0;
					}

					.rdrDateInput > input {
						height: 13.6px;
						font-size: 16px;
						font-weight: normal;
						font-stretch: normal;
						font-style: normal;
						line-height: 0.85;
						letter-spacing: normal;
						text-align: center;
						color: $date-picker-text;
					}
				}

				.rdrDateDisplay .rdrDateInput:first-child {
					background-color: $form-item-background-color;
				}

				.rdrDateDisplay .rdrDateInput:not(:first-child) {
					background-color: $container-background-color;
				}
			}

			.rdrMonthAndYearWrapper {
				border-bottom: solid 1px $select-border-color;
				height: 55px;
				padding: 0;

				.rdrMonthAndYearPickers {
					height: 13.6px;
					font-size: 16px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 0.85;
					letter-spacing: normal;
					text-align: center;
					color: $date-picker-text;
				}
			}

			.rdrMonths {

				.rdrMonth {

					.rdrDays {

						.rdrDay {
							height: 48px;

							.rdrStartEdge {
								color: $pragma-color !important;
							}

							.rdrInRange {
								color: $container-background-color !important;
							}

							.rdrInRange + .rdrDayNumber > span {
								color: black !important;
							}

							.rdrInRange + span + .rdrDayNumber > span {
								color: black !important;
							}

							.rdrEndEdge {
								color: $pragma-color !important;
							}
						}
					}
				}
			}
		}
	}
}