@import '../../themes/colors.scss';

.sideBar {
	padding-top: 40px;

	.sideBarTab {
		width: 80px;
		min-width: 80px;
		height: 64px;
		color: $icon-color;
	}

	.sideBarTabIndicator {
		height: 64px !important;
	}
}
