@import '../../../themes/colors.scss';

$checkboxHeight: calc(10px + 0.4vmin);
$checkboxWidth: calc(10px + 0.4vmin);

%checkboxSize {
	height: $checkboxHeight;
	width: $checkboxWidth;
}

.checkbox-label {
	font-size: $checkboxHeight !important;
	color: $checkboxLabelColor;
}

.default-checkbox {
	height: calc(10px + 1vmin);
	width: calc(10px + 1vmin);
	max-height: 20px;
	max-width: 20px;
}

.checkbox-icon-style  {
	@extend %checkboxSize;
	border-radius: 2px;
	color: $link-enabled;
	border: solid 1px $link-enabled;
}

.checkbox-icon-checked-style {
	@extend %checkboxSize;
	color: $link-enabled;
	border-radius: 2px;
}
