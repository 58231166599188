.app-bar {
	position: relative;
}

.flex {
	flex: 1;
}

.img-container {
	position: relative;
	flex: 1;
	padding: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.img {
	max-width: 100%;
	max-height: 100%;
}
