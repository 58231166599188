@import '../../themes/colors.scss';

.filter {
	display: flex;
	align-items: baseline;

	.label {
		margin-right: 13px;
		font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: right;
    color: $link-disabled;
	}

	.MuiSelect-icon {
		color: $select-icon-color;
	}

	.MuiInputBase-input {
		font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: $link-disabled;
    text-align: left;
	}
}

.MuiList-root {
	list-style-position: inside !important;	
}

.MuiMenuItem-root	 {
	display: flow-root !important;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

