@import '../../themes/colors.scss';

.subheader-form-report {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
	margin-bottom: 10px;
	padding: 0px 47px;

	.left-side {
		display: flex;
		align-items: center;
		flex-direction: row;

		.back-button {
			margin-right: 10px;
		}

		.title {
			font-size: 18px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: normal;
			color: $card-text-color;
		}
	}

	.actions {
		display: flex;
		align-items: center;
		flex-direction: row;

		.filter-control {
			min-width: 244px;
		}

		.add-button {
			color: $cornflower-blue;
			border: solid 1px $border-color;
			border-radius: 6px;
			margin-left: 16px;
			margin-right: 25px;
		}

		.action-button:nth-child(2) {
			margin-left: 5px;
			margin-right: 5px;
		}
	}
}
