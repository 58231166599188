@import '../../themes/colors.scss';

.table-cell {
  font-size: 18px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.11 !important;
  letter-spacing: normal;
  color: $card-text-color !important;
}
