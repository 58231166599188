.profile-menu {
	> ul {
		padding: 0px;
	}

	.card {
		width: 340px;

		.card-avatar-container {
			padding: 16px;

			.card-media {
				height: 308px;
				width: 308px;
			}
		}

		.card-actions {
			margin: 10px;
		}
	}
}
