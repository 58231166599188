	.common-section {
		display: flex;
		flex-direction: column;
				
		.top-section {
			display: flex;
			flex-direction: row;
			margin-left: 0px;
			// justify-content: space-between;

			.name-section {
				width: 100%;
				// margin-right: 10px;
			}
		}

		.bottom-section {
			display: flex;
			flex-direction: row;
			justify-content: space-between;

			.public-private-variable {
				width: 100%;
				margin-left: 10px;
			}

		}
	}

	.specs-section {
		
	}