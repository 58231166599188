@import '../../../../themes/colors.scss';

.section {
	.form-info {
		margin-top: 50px;
		margin-left: 45px;
		margin-right: 131px;
		border-radius: 6px;
		border: solid 1px $border-color;

		.form-info-title {
			display: flex;
			justify-content: start;
			align-items: center;
			height: 50px;
			background-color: $card-text-color;
			text-align: left;
			font-size: 18px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: normal;
			color: $white;
			padding-left: 24px;
		}

		.form-info-content {
			padding: 24px 24px 18px 24px;

			.form-info-control {
				.title-input {
					height: 64px;
					font-size: 36px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 0.67;
					letter-spacing: normal;
					color: $card-text-color;
				}

				.description-input {
					height: 48px;
					font-size: 18px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.11;
					letter-spacing: normal;
					color: $card-text-color;
				}
			}
		}

		.bottom-section {
			border-radius: 6px;
			height: 53px;
			background-color: $container-background-color;
			display: flex;
			justify-content: flex-end;

			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.43;
			letter-spacing: normal;
			color: $link-disabled;

			.more-vert-icon {
				margin-left: 30px;
			}
		}
	}
}