@import '../../themes/colors.scss';

.modal-paper {
	border-radius: 10px !important;
	box-shadow: 0 2px 50px 0 $modal-box-shadow-color;
	border: solid 1px $modal-paper-border;
	background-color: $modal-paper-background-color !important;

	.title-section {
		display: flex;
		align-items: center;
		justify-content: space-between;

			.title {
				color: $pragma-color;
				font-size: 22px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: 1.09;
				letter-spacing: normal;
				margin-left: 42px;
				margin-top: 32px;
			}

			.close-icon {
				color: $icon-color;
			}
	}

	.MuiDialogContent-root {
		padding: 63px 100px 0px 100px !important;
	}

	.dialog-actions {
		margin-top: 35px;
		margin-bottom: 32px;
		margin-right: 100px;
		padding: 0;
	}
}
