@import "../../../themes/colors.scss";

.add-blog-modal {
	.title-container {
		margin-bottom: 16px;

		.title-input {
			height: 64px;
			border-radius: 6px;
			background-color: $select-background-color;
		}
	}

	.autocomplete {
		margin-bottom: 32px;
	}

	.upload-container {
		margin-bottom: 17px;
	}
}
