@import '../../../../themes/colors.scss';

.form-add-view {
	padding-bottom: 40px;
	position: relative;

	.form-info {
		margin-top: 40px;
		margin-left: 47px;
		margin-right: 131px;
		border-radius: 6px;
		border: solid 1px $border-color;

		.form-info-title {
			display: flex;
			justify-content: start;
			align-items: center;
			height: 40px;
			background-color: $card-text-color;
			text-align: left;
			font-size: 18px;
			font-weight: 500;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.11;
			letter-spacing: normal;
			color: $white;
			padding-left: 24px;
		}

		.form-info-content {
			padding: 24px 24px 18px 24px;

			.form-info-control {

				.title-input {
					height: 64px;
					font-size: 36px;
					font-weight: bold;
					font-stretch: normal;
					font-style: normal;
					line-height: 0.67;
					letter-spacing: normal;
					color: $card-text-color;
				}

				.description-input {
					height: 48px;
					font-size: 18px;
					font-weight: normal;
					font-stretch: normal;
					font-style: normal;
					line-height: 1.11;
					letter-spacing: normal;
					color: $card-text-color;
				}

				.tag-field {
					margin-left: 4px;
				}
			}
		}
	}

	.action-buttons {
		display: flex;
		flex-direction: column;
		border-radius: 6px;
		border: solid 1px $icon-color;
		width: 58px;
		position: absolute;
		right: 47px;
		transition: top 0.5s;

		.action-button {
			color: $icon-color
		}
	}
}
