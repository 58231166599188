@import "../../themes/colors.scss";

%text-color {
	color: $card-text-color;
}

%text-style {
	font-stretch: normal;
	font-style: normal;
	letter-spacing: normal;
}

.blog-card {
	height: 339px;

	.content-area {
		display: flex;
		justify-content: space-between;
		height: 339px;
		align-items: unset;
		flex: 1;

		.card-content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			text-align: left;
			padding-top: 55px;
			padding-bottom: 53px;
			padding-left: 60px;
			flex: 1;
			max-width: 60%;
			word-break: break-all;

			.top-section {
				.title {
					@extend %text-color;
					font-size: 36px;
					color: $card-text-color;
					font-weight: bold;
					@extend %text-style;
					line-height: normal;
					margin-bottom: 14px;
					max-height: 2.34em;
					overflow: hidden;
				}

				.content {
					@extend %text-color;
					font-size: 18px;
					font-weight: normal;
					@extend %text-style;

					overflow: hidden;
					position: relative;
					line-height: 1.67em;
					max-height: 3.34em;
					text-align: justify;

					padding-right: 1em;
				}

				.content:before {
					content: "...";
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.content:after {
					content: "";
					position: absolute;
					right: 0;
					width: 1em;
					height: 1em;
					margin-top: 0.2em;
					background: white;
				}
			}

			.bottom-section {
				display: flex;
				font-size: 22px;
				color: $card-text-bottom-color;
				font-weight: normal;
				@extend %text-style;

				.bottom-edited {
					margin-right: 48px;

					.author-name {
						color: $card-text-color;
					}
				}
			}
		}

		.card-media-content {
			padding-top: 38px;
			padding-bottom: 37px;
			padding-right: 40px;

			.card-media {
				width: 540px;
				height: 264px;
			}
		}
	}
}
