@import '../../../themes/colors.scss';

.select-form-control {
	.MuiOutlinedInput-root {
		border-radius: 6px;
		background-color: $select-background-color;
	}

	.MuiSelect-icon {
		color: $select-icon-color;
	}

	.MuiInputBase-input {
		font-size: 14px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		letter-spacing: normal;
		color: $select-text-color;
	}

}