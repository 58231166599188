@import '../../../themes/colors.scss';

.rightTopCornerSection {
	display: flex;
	align-items: center; 
	margin-right: 10px;

	.filterFormControl {
		width: 242px;
		height: 39px;
	}

	.filterSelect {
		border-radius: 6px;
		border: solid 1px $icon-color;
		background-color: rgba(255, 255, 255, 0.64);
	}
}